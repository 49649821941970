<template lang="">
    <AddNewRequest />
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
name:"mulker",
mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Talep Ekle', route: '/talep-ekle' },
    ]);
},
components:{
    AddNewRequest: () =>import('@/components/request/AddRequest.vue')
}
}
</script>
<style lang="">

</style>